<template>
  <div class="body-form" >
     <!-- Delete Data Pop-up -->
     <NewModal v-if="isModal" @close="isModal = false" width="32%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
            letter-spacing: 0.3px;
            gap: 14px;
          "
        >
          <!-- Title -->
          <div style="color: #121212; font-size: 130%; font-weight: 600">
            Konfirmasi Deactive Data
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 90%">
            Apakah kamu yakin ingin menonaktifkan data 
            transaksi pelanggan ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 10px;
            gap: 16px;
            width: 100%;
          "
        >
          <!-- Cancel -->
          <Button
            name="BATALKAN"
            @click="isModal = false"
            width="174px"
            height="48px"
            style="
              font-size: 90%;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="YA, NONAKTIFKAN"
            @click="onDelete"
            width="174px"
            height="48px"
            style="
              font-size: 90%;
              font-weight: 600;
              background-color: #2D46CF;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <Loading v-if="isLoading" />
    <div class="cart-form">
      <h1>Ubah Data Transaksi User</h1>
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
      </v-breadcrumbs>
      <div class="sub-section">
        <h3>Info Pembelian</h3>
        <v-row>
          <v-col>
            <div class="content-form">
              <p>No. Invoice</p>
              <v-text-field
                outlined
                v-model="form.no_invoice"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div class="content-form">
              <p>Waktu Pembelian</p>
              <v-text-field
                outlined
                v-model="form.purchase_time"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="content-form">
              <p>Program</p>
              <v-text-field
                outlined
                v-model="form.program"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div class="content-form">
              <p>Tipe Kelas</p>
              <v-text-field
                outlined
                v-model="form.type"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="content-form">
              <p>Voucher</p>
              <v-text-field
                outlined
                v-model="form.voucher"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div class="content-form">
              <p>Nominal Transaksi</p>
              <v-text-field
                outlined
                v-model="form.nominal"
                class="form-input"
                dense
                clearable
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <!-- Line -->
        <div style="
          width: 100%;
          height: 1px;
          margin: 24px 0px 36px 0px; 
          background-color: #ACAFBF;">
        </div>
        <!-- Form Section 2 -->
        <div style="
          display: flex; 
          flex-direction: column; 
          gap: 14px;
          "
        >
          <!-- Personal Data -->
          <div style="
            display: flex; 
            flex-direction: column; 
            gap: 6px;
            "
          >
              <!-- Title -->
              <div style="
                display: flex; 
                flex-direction: column; 
                gap: 14px;
                "
              >
                <div style="color: #7B7E8C; font-size: 20px;">
                  Info Data Diri
                </div>
                <div style="
                  color: #36373F; 
                  font-weight: 600; 
                  font-size: 16px;">
                  Title
                </div>
              </div>
              <!-- Radio Button -->
              <v-radio-group v-model="form.title" row>
                <v-radio color="blue darken-4"
                  v-for="data in personalData"
                  :key="data"
                  :value="data"
                >
                  <template v-slot:label>
                    <div style="color: #36373F; font-weight: 500;">
                      {{ data }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
          </div>
          <!-- FullName -->
          <div style="
            display: flex; 
            flex-direction: column;
            gap: 8px;
            "
          >
            <!-- Title -->
            <div style="
              color: #36373F; 
              font-size: 14px; 
              font-weight: 600;
              "
            >
              Nama Lengkap
            </div>
            <v-text-field 
              outlined
              v-model="form.name"
              style="border-radius: 10px; font-size: 16px;"
              clearable 
            />
          </div>
          <!-- Phone Number -->
          <div style="
            display: flex; 
            flex-direction: column;
            gap: 8px;
            "
          >
            <!-- Title -->
            <div style="
              color: #36373F; 
              font-size: 14px; 
              font-weight: 600;
              "
            >
              No. Handphone
            </div>
            <div style="
              display: flex; 
              flex-direction: row; gap: 12px">
              <!-- Icon -->
              <div style="
                display: flex; 
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: #F7F9FD;
                border-radius: 10px;
                width: 10%;
                height: 56px;
                color: #575966;
                font-weight: 600;
                font-size: 16px;
                gap: 10px;
                padding: 0px 50px;">
                <!-- Flag -->
                <div style="
                  display: flex; 
                  justify-content: center; 
                  align-items: center;
                  "
                >
                  <IndonesiaFlag width="26" />
                </div>
                <!-- Text -->
                <div>+62</div>
              </div>
              <!-- Input -->
              <div style="width: 90%">
                <v-text-field 
                  outlined
                  v-model="this.form.no_hp"
                  style="border-radius: 10px; font-size: 16px;"
                  clearable 
                />
              </div>
            </div>
          </div>
          <!-- Email -->
          <div style="
            display: flex; 
            flex-direction: column;
            gap: 8px;
            "
          >
            <!-- Title -->
            <div style="
              color: #36373F; 
              font-size: 14px; 
              font-weight: 600;
              "
            >
              Email
            </div>
            <!-- Input -->
            <v-text-field 
              outlined
              v-model="form.email"
              style="border-radius: 10px; font-size: 16px;"
              clearable 
            />
          </div>
          <!-- Calendar -->
          <div style="
            display: flex; 
            flex-direction: column;
            gap: 8px;
            "
          >
            <!-- Title -->
            <div style="
              color: #36373F; 
              font-size: 14px; 
              font-weight: 600;
              "
            >
              Tanggal Lahir
            </div>
            <!-- Input -->
            <div>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    persistent-hint
                    readonly
                    outlined
                    style="border-radius: 10px; font-size: 16px;" 
                    v-bind="attrs"
                    v-on="on"
                    
                  >
                  <template v-slot:append >
                    <Calendar />
                  </template>
                </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title flat
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- Line -->
        <div style="
          width: 100%;
          height: 1px;
          margin: 24px 0px 36px 0px; 
          background-color: #ACAFBF;
          "
        >
        </div>
         <!-- Form Section 3 -->
        <div style="display: flex; flex-direction: column; gap: 10px; ">
          <!-- Switch -->
          <div>
            <v-switch
              v-model="switch1"
              inset
              color="blue darken-4"
            >
              <template v-slot:label>
                <div style="
                  color: #575966; 
                  font-weight: 400; 
                  font-size: 16px;
                  "
                >
                  {{ 
                    switch1 ? 
                    "Geser untuk menonaktifkan transaksi pelanggan ini" : "Transaksi dibatalkan" 
                  }}
                </div>
              </template>
            </v-switch>
          </div>
          <!-- Reason -->
          <div v-if="!switch1" style="
            border-radius: 10px;
            border: 1px solid #a09c9c;" :class="{'reason-hover': isHovered, 'reason-enter': isClick}">
            <v-textarea
              name="input-7-1"
              outlined
              auto-grow
              maxlength="300"
              label="Tulis di sini...."
              single-line
              v-model="form.reason"
              :rules="[rules.required, rules.counter]"
              counter
              style="border-radius: 10px; font-size: 16px; "
              class="custom-textarea" 
              @mouseover="isHovered = true"
              @mouseleave="handleMouseLeave"
              @click="isClick = true" />
          </div>
        </div>
        <!-- Submit Button -->
        <div style="
          display: flex; 
          flex-direction: row; 
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
          gap: 10px;">
           <!-- Cancel -->
           <div>
            <v-btn 
              class="ma-2"
              outlined
              color="#ACAFBF"
              style="
                font-family: 'Poppins', 'sans-serif'; 
                letter-spacing: 1px; 
                font-weight: 600;
                font-size: 13px;
                width: 130px;
                height: 42px;
                padding: 20px 60px;
                border-radius: 10px;
              "
            >
              Batalkan
            </v-btn>
          </div>
          <!-- Submit -->
          <div>
            <v-btn 
                v-if="!switch1"
                @click="isModal = true"
                depressed
                color="#2D46CF"
                style="
                  color: #FFF; 
                  font-family: 'Poppins', 'sans-serif'; 
                  letter-spacing: 1px; 
                  font-weight: 600;
                  font-size: 13px;
                  width: 130px;
                  height: 42px;
                  padding: 20px 60px;
                  border-radius: 10px;
                  "
              >
                Simpan Data
            </v-btn>
            <v-btn 
                v-if="switch1"
                disabled
                style="
                  color: #FFFF !important;
                  background-color: #ACAFBF !important; 
                  font-family: 'Poppins', 'sans-serif'; 
                  letter-spacing: 1px; 
                  font-weight: 600;
                  font-size: 13px;
                  width: 130px;
                  height: 42px;
                  padding: 20px 60px;
                  border-radius: 10px;
                  "
              >
                Simpan Data
            </v-btn>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Calendar from "@/components/icons/Calendar.vue";
import IndonesiaFlag from "@/components/icons/IndonesiaFlagRounded.vue";
import NewModal from "@/components/NewModal.vue";
import Button from "@/components/Button.vue";

export default {
  name: "TransactionEventForm",
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: '',
      menu1: false,
      isHovered: false,
      isClick: false,
      menu2: true,
      switch1: true,
      switch2: false,
      id: null,
      modal: false,
      isModal: true,
      startPicker: false,
      endPicker: false,
      isLoading: false,
      isEdit: false,
      tags: [],
      types: [
        { name: "Awareness", value: "awareness" },
        { name: "Sales", value: "sales" },
      ],
      statuses: [
        { name: "Aktif", value: true },
        { name: "Non Aktif", value: false },
      ],
      form: {
        no_invoice: "INV/202108/005",
        purchase_time: "2021-08-05 12:00:00",
        name: "John Doe",
        title: "Tuan",
        program: "Program 2",
        type: "Type 2",
        no_hp: "081234567890",
        email: "hicolleagues123@gmail.com",
        voucher: "Voucher 1",
        nominal: "Rp 100.000",
        reason: "Kendala jadwal pada user",
      },
      img_web: null,
      img_mobile: null,
      img_web_name: "",
      img_mobile_name: "",
      breadcrumbs: [
        {
          text: "Finance",
          disabled: false,
          href: "/transaction/statistic",
        },
        {
          text: "(B2C) Public Training",
          disabled: false,
          href: "/transaction/b2b/public-training",
        },
        {
          text: "Kelas Programming",
          disabled: true,
          href: "/transaction/b2c/public-training/detail",
        },
      ],
      personalData: ["Tuan", "Nyonya", "Nona"],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 300 || 'Max 300 characters',
      },
    };
  },
  components: {
    Loading,
    Calendar,
    IndonesiaFlag,
    NewModal,
    Button,
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    removeFirstPhoneNumber() {
      this.form.no_hp = this.form.no_hp.substring(1)
    },
    handleMouseLeave() {
      this.isHovered = false;
      this.isClick = false;
    },
    
  },

  created() {
    this.dateFormatted = this.formatDate(this.date);
  },

  mounted() {
    this.removeFirstPhoneNumber()
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fd;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 30px 40px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
}

.form-input {
  border-radius: 10px !important;
}

::v-deep .form-input .v-input__slot {
  height: 62px !important;
  margin-bottom: 20px;
  background-color: #ffffff !important;
}

::v-deep .form-input .v-text-field__details {
  display: none;
}

::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

::v-deep .form-select .v-input__slot {
  height: 56 !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.set-time {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}

.breadcrumbs {
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

::v-deep .v-breadcrumbs__divider {
  color: #162ca2 !important;
}

::v-deep .v-breadcrumbs__item {
  color: #162ca2;
}

::v-deep div.v-breadcrumbs__item {
  color: #7b7e8c !important;
}

.small-switch {
  transform: scale(0.75);
}

.pill {
  background-color: #f9f9f9;
  border-color: #d6d6d6;
  border: 1px solid;
  border-radius: 100px;
  padding: 4px 12px 4px 12px;
  color: #7b7e8c;
  text-transform: capitalize;
}

.pill-alert {
  background-color: #f7e7e6;
  border: 0px;
  color: #b80f0a;
  text-transform: none;
}

.watch-content {
  background-color: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 10px;
}

.watch-content a {
  text-decoration: none !important;
  color: #2d46cf;
  font-weight: 400;
}

.reason-hover {
  border: 1.5px solid #000 !important;
}

.reason-enter {
  border: 2px solid #1976d2 !important;
}

::v-deep .custom-textarea.v-text-field--outlined fieldset {
    border: 1px solid transparent;
    border-radius: 0px;
}

</style>

<!-- <template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        Edit Transaksi User
      </h1>
      <div class="form-ctn">
        <div class="invoice">
          <div class="content-form">
            <p>No. Invoice</p>
            <div class="form-info">
              <p>{{ form.invoice_number }}</p>
            </div>
          </div>
          <div class="content-form">
            <p>Tanggal Pembelian</p>
            <div class="form-info">
              <p>{{ form.created_at }}</p>
            </div>
          </div>
        </div>

        <div class="section-line"></div>
        <div class="customer">
          <h3 @click="toggleCollapse('customer')">
            Pelanggan
            <span>
              <CollapsedArrow
              :on="collapsed.customer"
              style="margin-right: 10px"
            />
            </span>
          </h3>
          <div class="content-form" v-show="!collapsed.customer">
            <p>Nama Lengkap</p>
            <div class="form-info">
              <p>{{ form.full_name }}</p>
            </div>
          </div>

          <div class="content-form" v-show="!collapsed.customer">
            <p>No. Handphone</p>
            <v-row>
              <v-col cols="2" class="phone">
                <p>Kode Negara</p>
                <div class="phone-logo">
                  <IndonesiaFlag :width="27" :height="18" />
                  <p>+62</p>
                </div>
              </v-col>
              <v-col>
                <div class="form-info">
                  <p>{{ form.phone_number }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="content-form" v-show="!collapsed.customer">
            <p>Email</p>
            <div class="form-info">
              <p>{{ form.email }}</p>
            </div>
          </div>
        </div>
        <div class="section-line"></div>
        <div class="product">
          <h3 @click="toggleCollapse('product')">
            Produk
            <span>
              <CollapsedArrow
              :on="collapsed.product"
              style="margin-right: 10px"
            />
            </span>
          </h3>
          <div class="content-form" v-show="!collapsed.product">
            <p>Program</p>
            <div class="form-info">
              <p>{{ form.class.name }}</p>
            </div>
          </div>
          <div class="content-form" v-show="!collapsed.product">
            <p>ID Produk</p>
            <div class="form-info">
              <p>{{ form.class.id }}</p>
            </div>
          </div>
          <div class="content-form" v-show="!collapsed.product">
            <p>Harga</p>
            <div class="form-info">
              <p>{{ formatCurrency(form.price, false) }}</p>
            </div>
          </div>
        </div>
        <div class="section-line"></div>
        <div class="payment">
          <h3 @click="toggleCollapse('payment')">
            Produk
            <span>
              <CollapsedArrow
              :on="collapsed.payment"
              style="margin-right: 10px"
            />
            </span>
          </h3>
          <div class="content-form" v-show="!collapsed.payment">
            <p>Kode Voucher</p>
            <div class="form-info">
              <p>{{ form.voucher_code }}</p>
            </div>
          </div>
          <div class="content-form" v-show="!collapsed.payment">
            <p>Diskon</p>
            <div class="form-info">
              <p style="color: #b80f0a">
                {{ formatCurrency(form.discount, true) }}
              </p>
            </div>
          </div>
          <div class="content-form" v-show="!collapsed.payment">
            <p>Total Pembayaran</p>
            <div class="form-info">
              <p style="color: #162ca2">
                {{ formatCurrency(form.total_price, false) }}
              </p>
            </div>
          </div>
          <div class="content-form">
            <p>Status pembayaran</p>
            <v-select
              label="Status pembayaran"
              v-model="form.status"
              :items="statusItems"
              :item-text="'name'"
              :item-value="'value'"
              class="form-input"
              solo
            />
          </div>
          <div class="content-form">
            <p>Attachment</p>
            <div class="form-info">
              <p>{{ form.url }}</p>
            </div>
          </div>
        </div>

        <div class="submit-box" v-if="!isWatch">
          <Button
            name="SIMPAN PERUBAHAN"
            width="260px"
            @click="onSubmit"
            :disabled="originalStatus == form.status"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/ilustration/Loading.vue";
import IndonesiaFlag from "@/components/icons/IndonesiaFlag.vue";
import CollapsedArrow from "@/components/icons/CollapsedArrow.vue";
import Button from "@/components/Button.vue";

export default {
  name: "TransactionEventForm",
  data() {
    return {
      originalStatus: "",
      collapsed: {
        customer: false,
        product: false,
        payment: false
      },
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      statusItems: [
        {
          name: "Draft",
          value: "DRAFT",
        },
        {
          name: "Menunggu Pembayaran",
          value: "WAITING_FOR_PAYMENT",
        },
        {
          name: "Pembayaran Lunas",
          value: "PAID",
        },
        {
          name: "Cancel",
          value: "CANCEL",
        },
        {
          name: "Expired",
          value: "EXPIRED",
        },
      ],
      form: {
        birthdate: "",
        created_at: "",
        discount: "",
        email: "",
        full_name: "",
        invoice_number: "",
        is_paid: "",
        phone_number: "",
        gender: "",
        total_price: "",
        payment_link_id: "",
        voucher_code: "",
        type_event: "",
        url: "",
        status: "",
        class: {
          method: "",
          name: "",
          price: "",
          id: 0,
        },
      },
    };
  },
  components: {
    Loading,
    Button,
    IndonesiaFlag,
    CollapsedArrow
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataTransactionEvent();
    },
    async getDataTransactionEvent() {
      this.isLoading = true;
      try {
        const resp = await this.$web_http.get(
          `/v1/transaction/${this.id}?category=Public Training`
        );
        const data = resp.data.data.transaction;
        this.form = {
          ...data,
          is_paid: data.is_paid ? "Sudah membayar" : "Belum membayar",
          birthdate: this.$helpers.formatDate(data.birthdate),
          created_at:
            this.$helpers.formatDateWithTimeAndTimeZone(data.created_at) +
            "WIB",
        };
        this.originalStatus = this.form.status;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        await this.$web_http.patch(
          `/v1/transaction/status/${this.id}`, {
            status: this.form.status,
            payment_link_id: this.form.payment_link_id
          }
        );
        setTimeout(() => {
          this.$router.push("/b2c/public-training");
        }, 2000);
      } catch {
        alert("harap isi semua field yang tersedia");
        this.isLoading = false;
      }
    },
    formatCurrency(amount, isDiscount) {
      return this.$helpers.formatCurrency(amount, isDiscount);
    },
    toggleCollapse(section) {
      // Use the provided section parameter to toggle the corresponding class
      this.collapsed[section] = !this.collapsed[section];
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>
<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.no-space {
  margin-bottom: -20px;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}
.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}
.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}
.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}
.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}
.section-line {
  width: 100%;
  height: 1px;
  background: #acafbf;
  margin-bottom: 10px;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.phone {
  width: 130px;
  height: 50px;
  border-radius: 10px;
  margin-top: 12px;
  margin-left: 12px;
  background: var(--Ghost-White, #f7f9fd);
}
.phone p {
  margin-top: -9px;
  font-size: 10px;
  color: #575966;
}
.phone-logo {
  margin-top: -5px;
  display: flex;
  align-items: center;
  background: #f7f9fd;
  width: 70px;
  height: 35px;
}

.phone-logo p {
  margin-bottom: 7px;
  margin-left: 3px;
  margin-top: 4px;
}

.form-info {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 14px 20px;
  margin-bottom: 20px;
  align-items: center;
  border-radius: 15px;
  background: var(--Ghost-White, #f7f9fd);
}

.form-info p {
  font-weight: normal !important;
  color: #36373f;
}

.customer h3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.customer.collapsed .content-form {
  max-height: 0;
  overflow: hidden;
}

.product h3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product.collapsed .content-form {
  max-height: 0;
  overflow: hidden;
}

.payment h3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.payment.collapsed .content-form {
  max-height: 0;
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style> -->
